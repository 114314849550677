<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Internal Transfer History
                    </h5>
                  </div>
                </div>
              </div>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      ><span class="red--text"><strong>* </strong></span>From
                      (Start date)</label
                    >

                    <v-menu
                      ref="start_date_menu"
                      v-model="start_date_menu"
                      :close-on-content-click="false"
                      :return-value.sync="start_date_menu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="start_date"
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="Start Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="start_date"
                        :max="end_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="start_date_menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.start_date_menu.save(start_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                    <label class="text-md text-typo font-weight-bolder ms-1"
                      ><span class="red--text"><strong>* </strong></span>To (End
                      date)</label
                    >

                    <v-menu
                      ref="end_date_menu"
                      v-model="end_date_menu"
                      :close-on-content-click="false"
                      :return-value.sync="end_date_menu"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="end_date"
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                            mt-5
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="To Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="end_date"
                        :min="start_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="end_date_menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.end_date_menu.save(end_date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row> </v-card-text
              ><v-card-actions dense class="card-padding d-flex justify-end">
                <v-btn
                  @click="clear"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    px-6
                  "
                  >Clear</v-btn
                >

                <v-btn
                  @click="search()"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    px-6
                  "
                  >Search</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card
        class="card-shadow border-radius-xl mt-5"
        v-show="dispatchMaster.length > 0"
      >
        <v-card-text class="card-padding">
          <!-- Data Table Master -->

          <v-data-table
            :headers="masterHeader"
            :items="dispatchMaster"
            item-key="id"
            mobile-breakpoint="0"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
            fixed-header
            class="table"
          >
            <template v-slot:[`item.status`]="{ item }">
              <span>
                <v-chip
                  label
                  small
                  :ripple="false"
                  :color="syncStatus(item).color"
                >
                  {{ syncStatus(item).label }}
                </v-chip>
              </span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <span>
                {{ formatDate(item.date) }}
              </span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-row>
                <v-col>
                  <v-btn
                    :ripple="false"
                    icon
                    text
                    width="auto"
                    class="
                      pe-4
                      ps-0
                      mb-0
                      text-default text-capitalize
                      icon-move-right
                      align-center
                    "
                    v-if="item.status != 'DRAFT'"
                    @click="onNavigate(item)"
                  >
                    Details
                    <v-icon right dark size="12" class="ms-0 mt-1">
                      fa fa-arrow-right
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    icon
                    text
                    width="auto"
                    class="
                      pe-4
                      ps-0
                      mb-0
                      text-default text-capitalize
                      icon-move-right
                      align-center
                    "
                    v-if="item.status == 'DRAFT'"
                    @click="draftView(item)"
                  >
                    View Draft
                    <v-icon right dark size="12" class="ms-0 mt-1">
                      fa fa-arrow-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  created() {
    this.setDates();
  },
  data() {
    return {
      overlay: false,
      start_date_menu: false,
      end_date_menu: false,
      start_date: null,
      end_date: null,
      // Master Table
      dispatchMaster: [],
      masterHeader: [
        {
          text: "Date",
          value: "date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        {
          text: "From",
          value: "from_warehouse",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        {
          text: "To",
          value: "to_warehouse",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        {
          text: "po number",
          value: "po_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        {
          text: "so number",
          value: "so_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        {
          text: "Items",
          value: "child_count",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: true,
        },
        {
          text: "Status",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
        {
          text: "Actions",
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  methods: {
    syncStatus(item) {
      if (item.status == "SYNCED") {
        return { label: "SYNCED", color: "rgba(105, 240, 174, 0.5)" };
      } else if (item.status == "NOT SYNCED") {
        return { label: "NOT SYNCED", color: "rgba(237, 63, 59, 0.5)" };
      } else if (item.status == "DRAFT") {
        return { label: "DRAFT", color: "rgba(237, 163, 59, 0.5)" };
      }
    },
    onNavigate(item) {
      this.$router.push({
        name: "Internal Transfer Detail",
        params: { internalTransfer: item, currentPage: this.currentPage },
      });
    },
    draftView(item) {
      this.$router.push({
        name: "view Draft",
        params: { item: item.id, currentPage: this.currentPage },
      });
    },
    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.start_date = this.formatDate(d);
      this.end_date = this.formatDate(d1);
      this.search();
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    // Searching Master Data
    async search() {
      try {
        this.overlay = true;
        let r = await api.get(this.start_date, this.end_date);
        this.dispatchMaster = r.filter((el) => el.child_count > 0);

        if (this.dispatchMaster.length === 0) {
          this.noDataAlert("No Data found");
        }
        this.overlay = false;
      } catch (error) {}
    },
    // Searching Child Data

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    noDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>

<style></style>
